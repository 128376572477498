export class AppDate  {

  constructor() {}



  getDatum(){

    let DA = this.getDateByStamp(Math.floor(Date.now() / 1000), 1);
    return DA['d']+'.'+DA['m']+'.'+DA['Y'];
  }



  getDateString(UNIX_timestamp){
    let DA = this.getDateByStamp(UNIX_timestamp, 1);
    return DA['d']+'.'+DA['m']+'. '+DA['Y'];
  }

  getTimestring(UNIX_timestamp){
    let DA = this.getDateByStamp(UNIX_timestamp, 1);
    return DA['H']+':'+DA['i'];
  }



  getTimestamp() {
    return Math.floor(Date.now() / 1000)
  }


  getDateByStamp(UNIX_timestamp, AS_OBJECT) {
    var o = {};
    var a = new Date(UNIX_timestamp * 1000);
    var year = a.getFullYear();
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var df = '';
    if (date <= 9) {
      df = '0';
    }
    var hf = '';
    if (hour <= 9) {
      hf = '0';
    }
    var vmm = '';
    var monthNum = (a.getMonth() * 1) + 1;
    if (monthNum <= 9) {
      vmm = '0';
    }
    var sf = '';
    if (sec <= 9) {
      sf = '0';
    }
    var mf = '';
    if (min <= 9) {
      mf = '0';
    }
    if (AS_OBJECT) {
      o['d'] = df + date;
      o['m'] = vmm + monthNum;
      o['Y'] = year;
      o['H'] = hf + hour;
      o['i'] = mf + min;
      o['s'] = sf + sec;
      return o;
    } else {
      return df + date + '.' + vmm + monthNum + '. ' + year;
    }
  }
}
