import {CUSTOM_ELEMENTS_SCHEMA,
  Injectable,
  NgModule, Renderer2
} from '@angular/core';
import {map} from 'rxjs/operators';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";

import { environment } from '../environments/environment';
import { RouterModule, Routes, RouteReuseStrategy} from '@angular/router';
import {AppRoutingModule} from "./app-routing.module";
import { AppComponent } from './app.component';

import { ServiceWorkerModule } from '@angular/service-worker';
 import {DashboardModule} from "./dashboard/dashboard.module";
import {DataTablesModule} from "angular-datatables";
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { NFC, Ndef } from '@awesome-cordova-plugins/nfc/ngx';
import {
  SignaturePadModule
} from "angular2-signaturepad";
@Injectable({providedIn: 'root'})

@NgModule({
  declarations: [AppComponent],

  imports: [IonicModule.forRoot(),SignaturePadModule, ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: environment.production,
  // Register the ServiceWorker as soon as the application is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
}),DataTablesModule,CommonModule,HttpClientModule,FormsModule,ReactiveFormsModule,RouterModule,AppRoutingModule,BrowserModule,HttpClientModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },Clipboard,NFC,Ndef],
  bootstrap: [AppComponent],
  exports:[RouterModule,AppRoutingModule,DashboardModule],

  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {}
