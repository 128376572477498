import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  delay } from "rxjs/operators";
import { DomSanitizer, SafeResourceUrl,SafeStyle,SafeUrl,SafeValue,SafeScript, SafeHtml} from '@angular/platform-browser';
import { catchError, map, tap } from 'rxjs/operators';
import {AppDataClass} from "../class/appDataClass";
import {
  SubscribableOrPromise,
  Observable,
  of,
  throwError
} from 'rxjs';
import {
  AuthGuardService
} from "./auth-guard.service";
import {
  Platform
} from "@ionic/angular";
@Injectable({
  providedIn: 'root'
})



export class UserService {


  private userUrl = '';
    dataUrl = '';
  status:any = 1;

  getTrustIt($sce){
    //return $sce;

    return   this.sanitizer.bypassSecurityTrustUrl($sce); // $sce; //
  }

  output:SafeHtml;


  response:any = '';
  token: Promise<any>|null = null;
  datas: Promise<any>|null = null;


  private resolve: Function|null = null;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(public platform:Platform,public guard:AuthGuardService,public sanitizer: DomSanitizer,private appdatas:AppDataClass,private http: HttpClient) {
    this.dataUrl = appdatas.userUrl();

  }




  async getToken(mail,pass) {
    this.userUrl = this.appdatas.loginUrl();
    var head = this.appdatas.loginHeader(mail,pass)
    this.httpOptions = {
      headers: new HttpHeaders(head),
    }
    this.response = "";
    this.response = await this.http
      .get<any>(this.userUrl,this.httpOptions)
      .pipe(delay(500))
      .toPromise();

    if(this.response) {
      if (this.response.token != undefined) {
          return this.response.token;
        } else {
          if (this.response.error != undefined) {
            return -1;
          }
          else{
             return -2;
          }
        }
    }
  }





   async loadUserDatas(){
    this.httpOptions = {
      headers: new HttpHeaders({ 'Isallowed': this.guard.getToken() })
    };

     this.response = "";
     return   await this.http
     .get<any>(this.dataUrl,this.httpOptions)
       .pipe(delay(500)).toPromise();
   }




}
