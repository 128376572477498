import {
  Injectable,
  NgModule
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DashboardPage} from "./dashboard.page";
import {
  RouterModule
} from "@angular/router";
import {
  AbnahmenPageModule
} from "../tools/abnahmen/abnahmen.module";
@Injectable({
  providedIn: 'root'
})
@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    RouterModule,
    AbnahmenPageModule
  ],
  declarations: [DashboardPage]
})
export class DashboardModule {}
