import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AbnahmenPage } from './abnahmen.page';
const routes: Routes = [
  {
    path: '',
    component: AbnahmenPage
  },
  {
    path: ':obj_id',
    loadChildren: () => import('./vorgang/vorgang.module').then( m => m.VorgangPageModule)
  },
  {
    path: ':obj_id/protokoll',
    loadChildren: () => import('./protokoll/protokoll.module').then( m => m.ProtokollPageModule)
  },

  {
    path: ':obj_id/protokoll/abschluss',
    loadChildren: () => import('./abschluss/abschluss.module').then( m => m.AbschlussPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AbnahmenPageRoutingModule {}
