import {
  Component,
  Injectable,
  OnInit,
  ViewChild
} from '@angular/core';
import {AppRoutingModule} from "../app-routing.module";
import {AuthGuardService} from "../api/auth-guard.service";
import {UserService} from "../api/user.service";
import {fileClass} from "../class/fileClass.service";
import {
  AppDate
} from "../class/dateClass";

import {
  AbnahmelisteComponent
} from "../components/abnahmeliste/abnahmeliste.component";

@Injectable({providedIn: 'root'})

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})

@ViewChild(AbnahmelisteComponent)



export class DashboardPage implements OnInit {
  appDir = 'bewoappdatas';
  DEBUGMODE = false;
  datum       ='';
  USER:any    = {'email':'','name':'','datas':{}};
  USERFILE    =  '';
  SIGNAFILE   = '';
  SHOWMOREOF = false;
  constructor(public liste:AbnahmelisteComponent,private file:fileClass,private userservice:UserService,private guard:AuthGuardService,private routes:AppRoutingModule) {


    var D            = new AppDate();
    this.datum       = D.getDatum();
    this.USERFILE    = this.appDir+'/userdatas.nb';
    this.file.readdir(this.appDir).then(b=>{
      if(!b){
        this.file.mkdir(this.appDir).then(e=>{
          this.elogger('APPDIR  ANGELEGT');
        });
      }
      else{


      }

    });
  }


  opendash(){


    // check ob Userdaten da sind ///
    this.file.readDatafile(this.USERFILE).then(a => {
        if(a['data']!=undefined){
          this.USER      = a['data'];
          this.SIGNAFILE = this.appDir+'/signatur-'+this.USER.datas.id+'.nb';
          this.liste.starter();
          this.SETSIGNATURE();
          this.SHOWMOREOF = true;
          this.elogger('USER VON FILE');
          this.elogger(this.USER);
        }
        else{
         this.LOADUSER();
          this.liste.starter();
        }
    });
  }


  logout(){
    this.file.deleteFile(this.USERFILE).then();
    this.guard.logout();
  }

  saveUser(){
    this.file.writeDatafile(this.USERFILE,this.USER).then(d=>{
     this.elogger('USERFILE GESPEICHERT '+d);
      this.elogger(this.USER);
      localStorage.setItem('user_id',this.USER.id);
      this.SIGNAFILE =  this.appDir+'/signatur-'+this.USER.id+'.nb';
      this.SETSIGNATURE();
    });
  }





  SETSIGNATURE(){
    this.file.readDatafile(this.SIGNAFILE).then(a => {
      if(a['data']!=undefined) {
        this.USER.signatur = a['data'];
      }

    });
  }


  LOADUSER(){
    this.getUserdatas().then(b=>{
      this.USER = b.result;
      this.USER.lastlog = '';
      this.saveUser();

    }).finally(()=>{
      this.elogger('USER Geladen..');
    });
  }


  async getUserdatas(){
    return  this.userservice.loadUserDatas();

  }

  elogger(v){
    if(this.DEBUGMODE) {
     console.log(v);
    }
  }

  ionViewWillEnter() {
    this.SHOWMOREOF = true;
    this.opendash();
    this.liste.starter();
  }


  ngOnInit() {

 //   this.liste.DirDatas().then(b=>{});

  }

}
