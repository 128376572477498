import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AbnahmenPageRoutingModule } from './abnahmen-routing.module';
import { AbnahmenPage } from './abnahmen.page';
import {
  AbnahmelisteComponent
} from "../../components/abnahmeliste/abnahmeliste.component";
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AbnahmenPageRoutingModule
  ],
  exports: [
    AbnahmelisteComponent
  ],
  declarations: [AbnahmenPage, AbnahmelisteComponent]
})
export class AbnahmenPageModule {}
