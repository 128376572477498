import {
  Component,
  Injectable,
  Input,
  OnInit
} from '@angular/core';
import {
  ProtokollFunction
} from "../../class/ProtokollFunctionClass";
import {
  fileClass
} from "../../class/fileClass.service";
import {
  AppDataClass
} from "../../class/appDataClass";
import {
  ObjektService
} from "../../tools/objekte/objekt.service";
import {
  ActivatedRoute
} from "@angular/router";
@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-abnahmeliste',
  templateUrl: './abnahmeliste.component.html',
  styleUrls: ['./abnahmeliste.component.scss'],
})

export class AbnahmelisteComponent implements OnInit {
  @Input() fresher:     any    = {};

  maindir = '';
  ABNAHMEN:any = {};
  ABNAHMELISTE = {};
  MENGE:number        = 0;
  abnahmearten: any = {
    'abnahme': 'Abnahme',
    'vorabnahme': 'Vorabnahme',
    'uebergabe': 'Übergabe'
  };
  constructor(private pfunct: ProtokollFunction, private file: fileClass, private appy: AppDataClass, private objektservice: ObjektService, private actRoute: ActivatedRoute) {
     this.ABNAHMEN = {};


  }

  starter(){
    this.MENGE = 0;
    this.ABNAHMELISTE = {};
    this.ABNAHMEN = {};
    this.DirDatas().then();
  }


  deleteAbnahme(path){

  if(confirm('Wirklich löschen?')){
    this.file.readdir(path).then(b=> {
      if(b['files'].length>0)
        this.file.rmdir(path).then(f=>{
          this.pfunct.presentToast('Die Daten wurden erfolgreich entfernt!').then(b => {});
          this.DirDatas().then();
        });
    });
  }
  }



  loadProtokoll(nr,dir){

    var TABLEDATAS = {};
    this.file.readDatafile(this.maindir+'/bewo-abnahmen/'+dir+'/objekt.txt').then(b => {
      if (b['data'] != undefined) {
        var     ds  = b['data'];
        var     obj = ds.object;
        var     anwesend   = ds.mieter.anwesend;
        var key    = anwesend.datum;
        this.MENGE++;

        TABLEDATAS['obj_id']        =  nr;
        TABLEDATAS['dir']           =  this.maindir+'/bewo-abnahmen/'+dir;
        TABLEDATAS['datum']         =  ds.datum;
        TABLEDATAS['abnahmeart']    =  this.abnahmearten[ds.abnahmeart];
        TABLEDATAS['status']        =  0;
        TABLEDATAS['mitarbeiter']   =  ds.mieter.anwesend.mitarbeiter;
        TABLEDATAS['adresse']       =  obj['strasse']+' '+obj['plz']+' '+obj['ort'];
        TABLEDATAS['step']          =  'OBJEKT';
        TABLEDATAS['nummer']          = obj['wohnungsnummer'];
        TABLEDATAS['vertrag']          = obj['ver_nr'];

        var bz = obj['raeume_bez_bestand']+'R ';
        if(obj['etg_bez'] !== ''){
          bz += ' | '+obj['etg_bez'];
        }
        if(obj['ve_lage'] !== ''){
          bz += ' | '+obj['ve_lage'];
        }
        TABLEDATAS['bezeichnung']   =      bz;
        var gw = "Vermietung";
        if(obj.nza_bez  == 'gewerbliche Nutzung') {
          gw = 'Gewerbe';
        }
        TABLEDATAS['nza'] = gw;


        this.file.readDatafile(this.maindir+'/bewo-abnahmen/'+dir+'/protokolldaten.txt').then(b => {
          if (b['data'] != undefined) {
            var pr   = b['data'];
            anwesend = pr.anwesend
            //// WIR LADEN VOM PROTOKOLL
            TABLEDATAS['status']        =  parseFloat(pr.status);
            TABLEDATAS['step']          =  'PROTOKOLL';
            TABLEDATAS['mietername']    =  pr.anwesend.mietername;
            TABLEDATAS['laufzeit']      =  pr.anwesend.v_laufzeit || lz;
            TABLEDATAS['bemerkung']     =  pr.bemerkung || '';

            this.ABNAHMELISTE[key] = TABLEDATAS;
          }
          else{
            var mi = '';
            var lz = '';
            if(ds.abnahmeart != 'uebergabe' ){
              if(ds.mieter.mieter.namen[0] !== undefined) {
                mi = ds.mieter.mieter.namen[0]['name'] + ' ' + ds.mieter.mieter.namen[0]['nachname'];
              }
              lz = 'bis '+ds.mieter.mieter.vertrag_ende;
            }
            else{
              if(ds.mieter.neuer_mieter.namen[0] !== undefined){
                mi = ds.mieter.neuer_mieter.namen[0]['name']+' '+  ds.mieter.neuer_mieter.namen[0]['nachname'];
                lz = 'ab '+ds.mieter.mieter.vertrag_beginn;
              }
            }
            TABLEDATAS['mietername']    =  mi;
            TABLEDATAS['laufzeit']      =  lz;
            TABLEDATAS['bemerkung']     =  '';
            this.ABNAHMELISTE[key] = TABLEDATAS;
          }
        })
      }
      else{
        this.file.readdir(this.maindir+'/bewo-abnahmen/'+dir).then(b=> {
          if(b['files'].length < 1)
            this.file.rmdir(this.maindir+'/bewo-abnahmen/'+dir).then(f=>{
            });
        });
      }
    });

  }



  listBuilder(){
    this.ABNAHMELISTE = {};
    this.MENGE = 0;
    for(let k in this.ABNAHMEN){
      var dir = this.ABNAHMEN[k];
      var nr =  parseInt(this.ABNAHMEN[k].replace('objekt-',''));
      this.loadProtokoll(nr,dir);
    }
  }
    async DirDatas(){
    this.file.readdir(this.maindir+'/bewo-abnahmen').then(b=> {
      if (b['files'] != undefined && b) {
      this.ABNAHMEN = b['files'];
      this.listBuilder();

    }
  });
  }
  orderByRowNum = (a, b) => {
    return a
  }



    ngOnInit() {
      this.DirDatas().then();

    }

}
