import { Component, OnInit,Input } from '@angular/core';
import {AuthGuardService} from "../api/auth-guard.service";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import {UserService} from "../api/user.service";
import {UserClass} from "../class/userClass";
import {  delay } from "rxjs/operators";
import {Md5} from 'ts-md5/dist/md5';
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  msg         = '';
  msgClass    = '';
  usermail    = '';
  userpass:any    = '';
  userid      = '';
  token:any   = '';
  spinClass   = '';
  version     = '1.01';
  errorMsgHide    = 1;
  superAdminName = 'superAdmin@nordic-bd.de';
  superAdminPass = '4534-afdfD!-adshfH65';

  constructor(private userService:UserService, private router: Router,private guard:AuthGuardService) { }


  clearMsg(){
    this.errorMsgHide = 1;
    this.msg = '';
  }


  loginError(code){
    if(code == 0){
      this.msg = 'Leider keine Verbindung zum Internet oder zur Api. Für die Nutzung ist dies Notwendig.';
    }
    if(code == -1){
      this.msg         = 'Passwort oder E-Mail nicht richtig! Bitte erneut versuchen.';
    }
    this.msgClass    = 'dangerMessage';
    this.spinClass   = '';
    this.errorMsgHide    = 0;
    this.guard.setLevel(0);
  }



  afterSuccessLogin(){
    //// ALLES NACH ERFOLGREICHEN LOGIN ////
    this.spinClass   = '';
    this.guard.setToken(this.token);
    this.guard.setLevel(1);
    this.router.navigate(["/"]).then(r => {});

  }


  logincheck(usermail,pw){
    this.guard.clearToken();
    document.getElementById('logmessages').innerHTML = '';
    const md5 = new Md5();
    this.errorMsgHide = 1;
    this.msg = '';
    var error = '';
    this.usermail = usermail;
    this.userpass = md5.appendStr(pw).end();
    this.spinClass = 'loadNow';


    if (!this.usermail) {
      error += 'E-Mail fehlt <br>';
    }
    if (!this.userpass) {
      error += 'Ohne Passwort keinen Zutritt...';
    }

    if (error.length > 0) {
      this.msg = '';
      this.errorMsgHide = 0;
      document.getElementById('logmessages').innerHTML = error;
      this.msgClass = 'dangerMessage';
      this.spinClass = '';
      return false;
    }
    else {
      if (this.usermail == this.superAdminName && this.userpass == this.superAdminPass) {
        this.guard.setLevel(1);
        this.router.navigate(["options"]).then(r => {
        });
      }
      this.SbmLogin().then(b=>{
      });
    }
  }

  async SbmLogin(){
      this.userService.getToken(this.usermail,this.userpass).then(b=>{
      if(b != -1 && b != undefined) {
        this.token = b;
        return this.afterSuccessLogin();
      }
      if(b == -1) {
        this.loginError(-1);
      }
        if(b == -2) {
        this.loginError(0);
      }
    });
  }
  ngOnInit() {

  }
}
