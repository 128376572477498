import { Injectable } from '@angular/core';
import {AuthGuardService} from "../../api/auth-guard.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  delay } from "rxjs/operators";

import {Observable,of} from "rxjs";
import {
  Abnahme
} from "../abnahmen/abnahme.datas";

import {
  ApiTabDatas
} from "../abnahmen/protokoll/tab.datas";

import { catchError, map, tap } from 'rxjs/operators';
import {AppDataClass} from "../../class/appDataClass";
import {
  ReparaturDatas,
  SendMailInfo
} from "../reparaturen/reparatur.datas";
import {
  ReparaturDetails
} from "../reparaturen/details/details.datas";
import {
  ReparaturAssetsDatas
} from "../reparaturen/reparaturAssets.datas";
@Injectable({
  providedIn: 'root'
})
export class ObjektService {

 httpOptions:any = {};
 response: any = '';
 dataUrl = '';



  constructor(private guard:AuthGuardService,private appdatas:AppDataClass,private http: HttpClient) {}

  async loadObjektliste(){
    this.httpOptions = {
      headers: new HttpHeaders({ 'Isallowed': this.guard.getToken() })
    };
    this.dataUrl = this.appdatas.objektListUrl();
    this.response = "";
    return   await this.http
      .get<any>(this.dataUrl,this.httpOptions)
      .pipe(delay(500)).toPromise();
  }










  getDatas(id: number): Observable<Abnahme> {
    this.dataUrl = this.appdatas.vorgangUrl(id);
    var o  = {
       headers: new HttpHeaders({ 'Isallowed': this.guard.getToken() })
    };
    return this.http.get<Abnahme>(this.dataUrl,o).pipe(
      tap(_ =>{}),
      catchError(this.handleError<Abnahme>(`geDatas id=${id}`))
    );
  }



  getAppDatas(): Observable<ApiTabDatas> {
    this.dataUrl = this.appdatas.appDatasUrl();
    var o  = {
      headers: new HttpHeaders({ 'Isallowed': this.guard.getToken() })
    };
    return this.http.get<ApiTabDatas>(this.dataUrl,o).pipe(
      tap(_ => this.log(`HOLE NUN DIE APIDATAS`)),
      catchError(this.handleError<ApiTabDatas>(`getAppDatas Function! `))
    );
  }


  getReparaturAssets(): Observable<ReparaturAssetsDatas> {
    this.dataUrl = this.appdatas.appReparaturUrl()+'&auftrag_assets=1';
    var o  = {
      headers: new HttpHeaders({ 'Isallowed': this.guard.getToken() })
    };
    return this.http.get<ReparaturAssetsDatas>(this.dataUrl,o).pipe(
      tap(_ => this.log(`getReparaturByNfc HOLE NUN DIE Reparatur Assets DATEN`)),
      catchError(this.handleError<ReparaturAssetsDatas>(`getReparaturAssets Function! `))
    );
  }



SENDINFOBACK = {};

  sendRepInfoMail(id): Observable<SendMailInfo> {

    this.dataUrl = this.appdatas.appReparaturUrl()+'&send_done_mail='+id;
    var o  = {
      headers: new HttpHeaders({ 'Isallowed': this.guard.getToken() })
    };
    return this.http.get<SendMailInfo>(this.dataUrl,o).pipe(
      tap(_ => this.log(`SENDE MAIL STATUS FÜR DIE ID`)),
      catchError(this.handleError<SendMailInfo>(`FEHLER WEGEN ERROR UND SO `))
    );
  }


  getReparaturByNfc(nfc,alle): Observable<ReparaturDatas> {
    var t = 0;
    if(alle){
      t = 1;
    }
    this.dataUrl = this.appdatas.appReparaturUrl()+'&nfc='+decodeURIComponent(nfc)+'&alle='+t;
    var o  = {
      headers: new HttpHeaders({ 'Isallowed': this.guard.getToken() })
    };
    return this.http.get<ReparaturDatas>(this.dataUrl,o).pipe(
      tap(_ => this.log(`getReparaturByNfc HOLE NUN DIE NFC DATEN`)),
      catchError(this.handleError<ReparaturDatas>(`getReparaturByNfc Function! `))
    );
  }

  getReparaturByObjId(obj_id,alle): Observable<ReparaturDatas> {
    var t = 0;
    if(alle===true || alle === "true"){
      t = 1;
    }

    this.dataUrl = this.appdatas.appReparaturUrl()+'&repobj_id='+obj_id+'&alle='+t;
    console.log('alle ist',alle,this.dataUrl);

    var o  = {
      headers: new HttpHeaders({ 'Isallowed': this.guard.getToken() })
    };
    return this.http.get<ReparaturDatas>(this.dataUrl,o).pipe(
      tap(_ => this.log(`HOLE NUN DIE DATEN für das Objekt`)),
      catchError(this.handleError<ReparaturDatas>(`getReparaturByObjId Function! `))
    );
  }



  getReparaturAuftrag(id): Observable<ReparaturDetails> {
    this.dataUrl = this.appdatas.appReparaturUrl()+'&rep_details='+id;
    var o  = {
      headers: new HttpHeaders({ 'Isallowed': this.guard.getToken() })
    };
    return this.http.get<ReparaturDetails>(this.dataUrl,o).pipe(
      tap(_ => this.log(`HOLE NUN DIE DETAILS ZU ID `)),
      catchError(this.handleError<ReparaturDatas>(`getReparaturAuftrag Function! `))
    );
  }



  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }






}
