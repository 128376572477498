import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-abnahmen',
  templateUrl: './abnahmen.page.html',
  styleUrls: ['./abnahmen.page.scss'],
})
export class AbnahmenPage implements OnInit {

  title = 'Abnahmen';
  hideWhileLoad = false;
  constructor() { }

  ngOnInit() {


  }

}
