import {
  Injectable,
  NgModule
} from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from "./api/auth-guard.service";
import {
  LoginFormComponent
} from "./login-form/login-form.component";


import {
  DashboardPage
} from "./dashboard/dashboard.page";


const routes: Routes = [
  {
    path: 'login',
    component: LoginFormComponent
  },
  {
    path: 'tools',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./tools/tools.module').then( m => m.ToolsPageModule)
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    component: DashboardPage
  },
  { path: '**',  redirectTo: '', pathMatch: 'full' },


];

@Injectable({providedIn: 'root'})

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule{}
